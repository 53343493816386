import Vue from 'vue';
import App from './App.vue';
import '@/utils/rem';
import 'animate.css';
import '@/assets/styles/reset.css';
import '@/assets/styles/elementui.css';
import '@/assets/styles/iconfont/iconfont.css';
import router from '@/routers';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import _ from 'lodash';
import '@/utils/directives';
import store from  '@/stores';
import Vant from 'vant';
import 'vant/lib/index.css';


Vue.config.productionTip = false;
Vue.use(_)
Vue.use(ElementUI);
Vue.use(Vant);

new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')